import React, { useCallback, useEffect } from 'react'
import BannerNotFound from '../../../assets/Image-not-found.webp'
import SkeletonLoading from '../../common/SkeletonLoading'
import { Suspense, memo } from 'react'
import { isValidStr } from '../../../common_functions'
import { useNavigate } from 'react-router-dom'
import campaignApi from '../../../store/reducers/campaigns/campaignApi'
import { CampaignInfo } from '../../../interfaces/campaign'

interface Props {
  link?: string
  bannerDesktopUrl?: string
  bannerMobileUrl?: string
  idCampaign: string
  enterpriseId: string
}

function CardPatrocinado({ link, bannerDesktopUrl, bannerMobileUrl, idCampaign, enterpriseId }: Props) {
  const [useSendClick, {data, isSuccess, isLoading, isError}] = campaignApi.useSendClickCampaignMutation()
  const navigation = useNavigate()

  const sendClick = async() => {
    const obj:CampaignInfo = {
      campaignId: idCampaign,
      enterpriseId: enterpriseId
    }
    useSendClick(obj)
  }

  const handleToPage = useCallback(async() => {
    // if (link != undefined) window.open(link, "_blank")
    await sendClick()
  }, [window.location])

  useEffect(() => {
    if(isSuccess || isError){
      if(link != undefined) {
        navigation(!link.includes('http') ? link + `?campanha=${idCampaign}` : link)
      }
    }
  }, [isSuccess, isError])

  return (
    <>
      <div className={`text-center ${isSuccess || isError ? "opacity-50" : ""}`}>
        <button
          value="Ver Patrocinado"
          disabled={!isValidStr(link)}
          onClick={handleToPage}
          className="w-full"
        >
          {window.innerWidth > 767 ? (
            <img
              src={bannerDesktopUrl ?? BannerNotFound}
              width="300px"
              loading='lazy'
              className="max-w-[1440px] h-auto md:w-full m-auto banner-patrocinado"
              alt="Banner Patrocinado"
            />
          ) : (
            <img
              src={bannerMobileUrl ?? bannerDesktopUrl ?? bannerMobileUrl}
              width="300px"
              loading='lazy'
              className="max-w-[1440px] h-auto md:w-full m-auto banner-patrocinado"
              alt="Banner Patrocinado"
            />
          )}
        </button>
      </div>
    </>
  )
}
export default memo(CardPatrocinado)
