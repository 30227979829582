import { EnterpriseData } from '../../interfaces/enterprise/enterpriseData'
import CardPatrocinado from '../Cards/CardPatrocinado'
import BaseInfos from './BaseInfos'

import { Suspense, useEffect, useState } from 'react'
import SkeletonLoading from '../common/SkeletonLoading'
import Differentials from './Differentials'
import Location from './Location'
import Proximities from './Proximities'
import FichaTecnica from './FichaTecnica'
import Tour360 from './Tour360'
import FinancingConditions from './FinancingConditions'
import Fonte from './Fonte'
import LastPublish from './LastPublish'
import { AboutCompany } from './AboutCompany'
import CardPatrocinadoByIndex from '../Cards/CardPatrocinadoByIndex'
import { CardFormContactLead } from '../Cards/CardFormContactLead'
import CardBannerFair from '../Cards/CardBannerFair'
import { v4 } from 'uuid'

interface Props {
  enterprise: EnterpriseData
}

export default function SectionDetails({ enterprise }: Props) {
  return (
    <div className="mt-10">
      <BaseInfos
        Id={enterprise.Id}
        Description={enterprise.Description}
        StatusId={enterprise.StatusId}
        ConstructionCompanyId={enterprise.ConstructionCompanyId}
      />

      <div className="my-5">
        <CardPatrocinadoByIndex
          position={1}
          enterpriseId={enterprise.Id}
          constructionCompanyId={enterprise.ConstructionCompanyId}
        />
      </div>
      <CardBannerFair enterpriseId={enterprise.Id} constructionCompanyId={enterprise.ConstructionCompanyId}/>
      <hr className=" z-[-1] relative opacity-10 mt-4" />
      {/* DIFERENCIAIS */}
      <Suspense fallback={<SkeletonLoading />}>
        <Differentials EnterpriseId={enterprise.Id} />
      </Suspense>
      {/* LOCALIZAÇÃO */}
      <Suspense fallback={<SkeletonLoading />}>
        <Location EnterpriseId={enterprise.Id} />
      </Suspense>
      {/* PROXIMIDADES */}
      <Suspense fallback={<SkeletonLoading />}>
        <Proximities EnterpriseId={enterprise?.Id} />
      </Suspense>
      {/* TOUR 360 */}
      <Suspense fallback={<SkeletonLoading />}>
        <Tour360 EnterpriseId={enterprise?.Id} />
      </Suspense>
      {/* CONDICOES DE FINANCIAMENTO  */}
      <Suspense fallback={<SkeletonLoading />}>
        <FinancingConditions EnterpriseId={enterprise.Id} />
      </Suspense>
      {/* FICHA TECNICA  */}
      <Suspense fallback={<SkeletonLoading />}>
        <FichaTecnica
          Title={enterprise?.Title}
          Realization={enterprise.ConstructionCompany}
          EnterpriseId={enterprise.Id}
        />
      </Suspense>
      {/* FONTE */}
      <Suspense fallback={<SkeletonLoading />}>
        <Fonte fonteUrl={enterprise.Url} />
      </Suspense>
      {/*DATA DE ATUALIZAÇÃO*/}
      <Suspense fallback={<SkeletonLoading />}>
        <LastPublish lastPublish={enterprise.LastPublish} />
      </Suspense>
      {/* FORM LEAD   */}
      <div className="lg:hidden">
        <CardFormContactLead formId={v4()} constructionId={enterprise.ConstructionCompanyId ?? ''} enterpriseId={enterprise.Id ?? ''} />
      </div>
      {/* SOBRE A EMPRESA  */}
      <Suspense fallback={<SkeletonLoading />}>
        <AboutCompany enterprise={enterprise}/>
      </Suspense>
    </div>
  )
}
